


























import Vue from 'vue';
export default Vue.extend({
  name: 'Empty',
  data(): Record<string, any> {
    return {
      isSdkReady: false, // 客戶sdk初始化判断
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    // 点击打开聊天窗
    openkefu() {
      if (this.isSdkReady) {
        window.ysf('open');
      }
    },
  },
});
