//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import MediaBox from '~/components/MediaBox';
import Join from '~/components/Join';

export default {
  name: 'Footer',
  components: { MediaBox, Join },
  props: {
    visibleLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      joinVisible: false,
      isSdkReady: false, // 客戶sdk初始化判断
      // lang: 'zh',
      langApi: '',
    };
  },
  computed: {
    showLinks() {
      if (/\/exhibition[-\d+]/.test(this.$route.path)) {
        const {
          f: firstIndustryId = '0',
          s: secondIndustryId = 0,
          g: area = 0,
          p: province = 0,
          c: city = 0,
          t: date = '0',
        } = this.$route.params;

        if (
          +firstIndustryId > 0 &&
          (+secondIndustryId > 0 || +secondIndustryId == 0) &&
          (+area === 0 || +area === 1) &&
          +province === 0 &&
          +city === 0 &&
          +date === 0
        ) {
          return true;
        }
      } else if (this.$route.path === '/') {
        return true;
      }
      return false;
    },
  },

  mounted() {
    // this.init();
  },
  methods: {
    // 客服SDK初始化
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    // 点击打开聊天窗
    openkefu() {
      if (this.isSdkReady) {
        window.ysf('open');
      }
    },
    handleCloseJoin() {
      this.joinVisible = false;
    },
  },
};
