






















































































import Vue from 'vue';
import { ElUploadInternalFileDetail } from 'element-ui/types/upload';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import { checkEmail } from '~/util/common';
import SingleUpload from '~/components/SingleUpload/index.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  components: { SingleUpload },
  props: {
    edit: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      formObj: {
        cardType: 1,
        email: '',
        identityCardBack: '',
        identityCardFront: '',
        mobile: '',
        post: '',
        sex: '',
        userCertificates: '',
        visitorIdentity: '',
        visitorName: '',
      },
      rules: {
        corporationName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
          // { validator: checkName, trigger: 'blur' },
        ],
        user: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
          // { validator: checkName, trigger: 'blur' },
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        tel: [
          { required: true, message: '公司电话不能为空', trigger: 'blur' },
          // { validator: checkTel, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          // { validator: checkPhone, trigger: 'blur' },
        ],
        userEmail: [
          { required: true, message: '联系人邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        cardType: [{ required: true, message: '证件类型不能为空', trigger: 'blur' }],
        visitorIdentity: [
          { required: true, message: '证件号不能为空', trigger: 'blur' },
          // { validator: checkIdCardNo, trigger: 'blur' },
        ],
        visitorNameEn: [
          { required: true, message: '姓名拼音不能为空', trigger: 'blur' },
          // { validator: checkName, trigger: 'blur' },
        ],
        visitorName: [
          { required: true, message: '人员姓名不能为空', trigger: 'blur' },
          // { validator: checkName, trigger: 'blur' },
        ],
        sex: [{ required: true, message: '性别不能为空', trigger: 'blur' }],
        post: [{ required: true, message: '职位不能为空', trigger: 'blur' }],
        userCertificates: [{ required: false, message: '证件照不能为空', trigger: 'change' }],
        identityCardFront: [{ required: false, message: '身份证图片不能为空', trigger: 'change' }],
        identityCardBack: [{ required: false, message: '身份证图片不能为空', trigger: 'change' }],
        companyBusinessLicense: [{ required: true, message: '营业执照不能为空', trigger: 'change' }],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          // { validator: checkPhone, trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      const visitorDetail = _.get(this.$store, 'state.visitor.visitorDetail', {});
      this.formObj = _.cloneDeep(visitorDetail);
    }, 10);
  },
  methods: {
    // 图片上传成功
    handleSuccess(res: any, _file: ElUploadInternalFileDetail, _fileList: ElUploadInternalFileDetail[], prop: string) {
      const $orderForm = this.$refs.orderform as ElForm;
      this.$set(this.formObj, prop, res);
      $orderForm.validateField(prop);
    },
    handleReset() {
      const $orderForm = this.$refs.orderform as ElForm;
      $orderForm.resetFields();
    },
    handleSubmit() {
      const $orderForm = this.$refs.orderform as ElForm;
      $orderForm.validate(async (valid) => {
        if (valid) {
          const uid = _.get(this.$store, 'state.global.user.userId', 0);
          const params: Record<string, any> = { uid, ...this.formObj };
          let action = `/api/visitor/create`;
          if (this.edit) {
            action = `/api/visitor/update`;
            params.id = this.$route.params.id;
          }
          const res: any = await this.$axios.post(action, params);
          if (res.code === ResponseCode.SUCCESS) {
            this.$message.success('操作成功!');
            this.$router.back();
          }
        }
      });
    },
  },
});
