
















































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';

// @ts-ignore
import * as domesticCity from './flightCode.json';

export default Vue.extend({
  name: 'AreaPopup',
  components: {},
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
    value: {
      type: Boolean,
      default: false,
    },
    searchName: {
      type: String,
      default: '',
    },
  },
  data(): any {
    return {
      activeName: 'hot',
    };
  },
  computed: {
    //所有城市列表
    domesticCityList: {
      get() {
        let list: any[] = [];
        for (let i in domesticCity.default) {
          list.push(...domesticCity.default[i]);
        }

        return list;
      },
    },
    searchResultList() {
      let result: any[] = this.domesticCityList.filter(
        (item: any) => item.name.indexOf(this.searchName) > -1
      );

      return result;
    },
  },
  mounted() {},
  methods: {
    handleClick(e: any) {
      this.$emit('on-change', e);
      this.$emit('input', false);
    },
  },
});
