

















































































































































import { HttpRequestOptions } from 'element-ui/types/upload';
import Vue from 'vue';
import _ from 'lodash';
import { uploadFile } from '~/util/oss';
import { ResponseCode } from '~/util/constant';
import { $axios, $store } from '~/util/api';

export default Vue.extend({
  name: 'Index',
  data(): any {
    const validateNickname = (
      _rule: any,
      value: string,
      callback: (param?: Error) => void
    ) => {
      const phoneRegex = /1[3-9]\d{9}/g;
      const landlineRegex =
        /((\+?\d{1,3}-?)?0\d{2,3}-?\d{2,3})\d{2,3}(-?\d{1,4}|$)/g;
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
      const specialCharRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;

      if (
        phoneRegex.test(value) ||
        landlineRegex.test(value) ||
        emailRegex.test(value) ||
        specialCharRegex.test(value)
      ) {
        callback(new Error('昵称不能包含手机号、电话号、邮箱或包含特殊字符'));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: false,
      activeStep: 0,
      industry: [],
      subIndustry: [],
      firstIndustry: [],
      secondIndustry: [],
      formObj: {
        avatar: '',
        nickName: '',
        companyName: '',
        email: '',
        position: '',
        companyIndustry: '',
      },
      industryList: [],
      langApi: 'cn',
      // rules: {
      //   avatar: [{ required: true, message: '请上传头像', trigger: 'change' }],
      //   nickName: [
      //     { required: true, message: '请填写昵称', trigger: 'blur' },
      //     { validator: validateNickname, trigger: 'blur' },
      //   ],
      //   email: [{ required: true, message: '请填写邮箱', trigger: 'blur' }],
      //   companyName: [
      //     { required: true, message: '请填写公司名称', trigger: 'blur' },
      //   ],
      //   position: [{ required: true, message: '请填写职务', trigger: 'blur' }],
      //   companyProduct: [
      //     { required: true, message: '请填写公司产品', trigger: 'blur' },
      //   ],
      // },
    };
  },
  mounted() {
    const lang = this.$route.query.lang;
    if (lang == 'zh') this.langApi = 'cn';
    if (lang == 'en') this.langApi = 'en';
    const { user } = $store.state.global;
    Object.assign(this.formObj, {
      avatar: user.avatar,
      nickName: user.nickName,
      companyName: user.companyName,
      email: user.email,
      position: user.position,
      companyIndustry: user.companyIndustry,
    });
    this.fetchIndustry();
    this.fetchMyIndustry();
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      try {
        // this.$login.hide();
        Vue.prototype.$completeInfo.hide();
      } catch (e) {
        console.log('用户社区完善资料235-235', e);
      }
      const redirect = _.get(this, '$route.query.redirect', '/');
      this.dialogVisible = false;
      this.formObj = {
        avatar: '',
        nickName: '',
        companyName: '',
        region: '',
        industry: '',
      };
      if (redirect) {
        this.$router.push(redirect);
      }
    },
    handleAvatarSuccess(res: any) {
      this.formObj.avatar = res;
    },
    beforeAvatarUpload(file: any) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是图片格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      // 直接返回false, 无法阻止上传
      if (isJPG && isLt2M) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error());
      }
    },
    // 上传
    async handleUpload(option: HttpRequestOptions) {
      const file = option.file;
      return await uploadFile(file);
    },
    getTag(data: any) {
      data.check = !data.check;
    },
    async submit() {
      this.industry.forEach((item: any) => {
        item.children.forEach((item1: any) => {
          if (item1.check) {
            this.industryList.push(item1.id);
          }
        });
      });
      if (this.formObj && this.formObj.avatar === '') {
        this.$message.error('请上传头像');
        return;
      }
      if (this.formObj && this.formObj.nickName === '') {
        this.$message.error('请填写昵称');
        return;
      }
      if (this.formObj && this.formObj.companyName === '') {
        this.$message.error('请填写公司名称');
        return;
      }
      if (this.formObj && this.formObj.position === '') {
        this.$message.error('请填写职务');
        return;
      }
      if (this.industryList && this.industryList.length === 0) {
        this.$message.error('请选择关注行业');
        return;
      }
      await this.fetchEditIndustry();
      this.formObj.companyIndustry = 0;
      const res: any = await $axios.post('/api/user/editInfo', this.formObj);
      if (res.code === ResponseCode.SUCCESS) {
        this.$message.success('操作成功!');
        $store.commit(
          'global/setUser',
          Object.assign($store.state.global.user, this.formObj)
        );
        this.hide();
      }
    },
    handlePrev() {
      this.activeStep--;
    },
    handleNext() {
      this.activeStep++;
    },
    async fetchIndustry() {
      const res: any = await $axios.get(
        `/api/industry/getAll?locale=${this.langApi}`
      );

      if (res.code === ResponseCode.SUCCESS) {
        res.data &&
          res.data.forEach((item: any) => {
            item.children.forEach((item1: any) => {
              item1.check = false;
            });
          });
        this.industry = res.data;
        this.firstIndustry = [res.data[0].industryId];
        this.subIndustry = res.data[0].children;
      }
    },
    async fetchMyIndustry() {
      const res: any = await $axios.get('/api/user/myIndustry');

      if (res.code === ResponseCode.SUCCESS) {
        this.secondIndustry = this.secondIndustry.concat(
          res.data.map((d: any) => +d.industryId)
        );
      }
    },
    async fetchEditIndustry() {
      await $axios.post('/api/user/myIndustry/edit', {
        industryList: this.industryList,
      });
    },
  },
});
