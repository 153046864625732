













import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: '聚',
    },
    size: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    first() {
      const text = this.text?.slice(-2) || '';
      if (/\d{2}/.test(text)) {
        return text;
      } else if (/\*+/.test(text)) {
        return this.text?.slice(0, 1) || 'U';
      } else {
        return this.text?.slice(0, 1) || 'U';
      }
    },
    randomColor() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
});
