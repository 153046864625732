



































































































import Vue from 'vue';
import Avatar from '~/components/Avatar/index.vue';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import { $cookies } from '~/util/api';
import { getXOssProcess } from '~/util/common';

export default Vue.extend<any, any, any, any>({
  name: 'FairUser',
  components: {
    Avatar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headimg: require('@/assets/images/headimg.jpg'),
      userVisible: false,
      ruleForm: {
        companyName: '',
        companyOfficialWebsite: '',
        email: '',
        productNames: [{ value: '' }],
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        productNames: [
          { required: true, message: '公司产品不能为空', trigger: 'blur' },
        ],
      },
      userId: 0,
    };
  },
  methods: {
    getXOssProcess,
    async toUser(id: number) {
      if (this.shouldLogin()) {
        this.userId = id;
        const res: any = await this.$axios.post(
          `/api/followeApi/checkFollowUserInfo`
        );
        if (res.code === '16002') {
          this.userVisible = true;
        } else {
          window.open(`/expouser/${id}.html`);
        }
        // this.userVisible = true;
      }
    },
    addDomain() {
      this.ruleForm.productNames.push({
        value: '',
      });
    },
    removeDomain(idx: number) {
      this.ruleForm.productNames.splice(idx, 1);
    },
    resetForm() {
      const $form = this.$refs.rule as ElForm;
      $form?.resetFields();
      this.userVisible = false;
    },
    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    submit() {
      const form = this.$refs.rule as ElForm;
      form.validate((valid) => {
        if (valid) {
          this.fecthSave();
        }
      });
    },
    async fecthSave() {
      this.ruleForm.productNames = _.cloneDeep(
        this.ruleForm.productNames.map((item: any) => item.value)
      );
      const res: any = await this.$axios.post(
        '/api/followeApi/updateFollowUserInfo',
        {
          ...this.ruleForm,
        }
      );
      if (res.code === '1') {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        this.resetForm();
        setTimeout(() => {
          window.open(`/expouser/${this.userId}.html`);
        }, 1000);
      }
    },
  },
});
