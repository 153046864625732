






























































































































































import Vue from 'vue';
import { OSS_BUCKET_URL } from '~/util/common';

export default Vue.extend({
  props: {
    visibleLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OSS_BUCKET_URL,
      isSdkReady: false, // 客戶sdk初始化判断
    };
  },
  mounted() {
    // this.init();
  },
  computed: {
    showLinks() {
      if (/\/exhibition[-\d+]/.test(this.$route.path)) {
        const {
          f: firstIndustryId = '0',
          s: secondIndustryId = 0,
          g: area = 0,
          p: province = 0,
          c: city = 0,
          t: date = '0',
        } = this.$route.params;

        if (
          +firstIndustryId > 0 &&
          +secondIndustryId === 0 &&
          (+area === 0 || +area === 1) &&
          +province === 0 &&
          +city === 0 &&
          +date === 0
        ) {
          return true;
        }
      } else if (this.$route.path === '/') {
        return true;
      }
      return false;
    },
  },
  methods: {
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    openkefu() {
      if (this.isSdkReady) {
        window.ysf('open');
      }
    },
  },
});
